import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { hostingTheme } from 'common/src/theme/hosting';
import {
  GlobalStyle,
  ContentWrapper,
} from '../containers/Hosting/hosting.style';
import { ResetCSS } from 'common/src/assets/css/style';
import Navbar from '../containers/Hosting/Navbar';
import Footer from '../containers/Hosting/Footer';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { ParallaxProvider } from 'react-scroll-parallax';
import SEO from '../components/seo';
import TopRibbon from '../containers/Hosting/TopRibbon';

import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';

import ContactSection3 from '../containers/Hosting/Contact2/infographics';
import { useStaticQuery, graphql, Link } from 'gatsby';
import InfographicsWrapper from './infographics.style';

const InfographicSection = ({
  name,
  sectionWrapper,
  row,
  col,
  col2,
  title,
  data,
}) => {
  const post = data.markdownRemark;
  return (
    <ThemeProvider theme={hostingTheme}>
      <ParallaxProvider>
        <SEO
          title={post.frontmatter.seo}
          description={post.frontmatter.seoDesc}
        />
        <ResetCSS />
        <GlobalStyle />
        <TopRibbon />
        <ContentWrapper className="InfographicsPage">
          <Sticky
            top={0}
            innerZ={9999}
            activeClass="sticky-nav-active"
            releasedClass="sticky-nav-inactive"
          >
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <InfographicsWrapper>
            <Box {...sectionWrapper}>
              <Container>
                <Box {...row}>
                  <Box {...col} className="text_content">
                    <Fade left cascade>
                      <Box>
                        <Heading {...title} content={post.frontmatter.title} />
                        <div dangerouslySetInnerHTML={{ __html: post.html }} />
                      </Box>
                    </Fade>
                  </Box>
                  <Box {...col}>
                    <ContactSection3 name={post} />
                  </Box>
                </Box>
              </Container>
            </Box>
          </InfographicsWrapper>
          <Footer />
        </ContentWrapper>
      </ParallaxProvider>
    </ThemeProvider>
  );
};

InfographicSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  col2: PropTypes.object,
  title: PropTypes.object,
};

InfographicSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: '120px',
    pb: ['0', '0', '50px', '50px', '50px'],
    id: 'case_study',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    ml: '-15px',
    mr: '-15px',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '100%', '100%', '49.99%', '49.99%'],
  },
  col2: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '100%', '100%', '49%', '49%'],
  },
  title: {
    fontSize: ['30px', '30px', '35px', '35px', '35px'],
    fontWeight: 'bold',
    color: '#0f2137',
    letterSpacing: '0.015em',
    mb: '25px',
    pt: '30px',
    textAlign: 'left',
    className: 'heading',
  },
};

export default InfographicSection;

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        seo
        seoDesc
      }
      fields {
        slug
      }
    }
  }
`;
